body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Merriweather Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .row {
  width:100% !important;
} */
.btn-shadow {
  box-shadow: 0.2rem .3rem 0.3rem rgba(0,0,0,.15)!important;
}

.content {
  /*min-height: calc(100vh - 187px);*/
  /*margin-top: 85px !important;*/
  margin:auto;
  max-width: 824px;  
  /* display: grid;
  grid-template-rows: auto 1fr auto; 
  background-color: green;*/
  
}
.blink-hard {
  animation: blinker 1s step-end infinite;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #397524 #DFE9EB;
}

/* Chrome, Edge and Safari */
.scrollbar::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}
.scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f6f6f6;
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #EEE;
}

.scrollbar::-webkit-scrollbar-track:active {
  background-color: #EEE;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #CCC;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #CCC;
}

.scrollbar::-webkit-scrollbar-thumb:active {
  background-color: #555;
}
/*
.accordion-button:not(.collapsed) {
  color: #FFF !important;
  background-color: #dc3545 !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
*/

@media (min-width: 1200px) {
  .h2, h2 {
      font-size: inherit;
  }
}
.pl-1 {
  padding-left: .25rem !important;
}
.pr-1 {
  padding-right: .25rem !important;
}
.pl-2 {
  padding-left: .5rem !important;
}
.pr-2 {
  padding-right: .5rem !important;
}
.pl-3 {
  padding-left: .75rem !important;
}
.pr-3 {
  padding-right: .75rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pr-4 {
  padding-right: 1rem !important;
}
.pl-5 {
  padding-left: 1.25rem !important;
}
.pr-5 {
  padding-right: 1.25rem !important;
}

.headerHover .accordion-button:focus {
  box-shadow: inherit !important;
}
.headerHover .accordion-button {
  padding: 10px 15px;
}

/* -- Branding -- */
:root {
  --primary-color: #C65032;
  --primary-alt-color:#FFFBF0;
  --secondary-color:#F6E9D7;
  --secondary-alt-color:#2D2D2D;
  --neutral-color:#000000;
  --neutral-alt-color:gray;
  --font-family:'system-ui',-apple-system,"Segoe UI",'Roboto',"Helvetica Neue","Noto Sans","Liberation Sans",'Arial','sans-serif',"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

.primary-text{
  color: var(--primary-color);
}
.secondary-text{
  color: var(--secondary-color);
}
.neutral-text{
  color: var(--neutral-color);
}

.primary-button{
  background-color: var(--primary-color) !important;
  color: var(--primary-alt-color) !important;
  border-color: var(--primary-color) !important;
}
.secondary-button{
  background-color: var(--secondary-color) !important;
  color: var(--secondary-alt-color) !important;
  border-color: var(--secondary-color) !important;
}
.neutral-button{
  background-color: var(--neutral-color) !important;
  color: var(--neutral-alt-color) !important;
  border-color: var(--neutral-color) !important;
}
.headerHover .accordion-button:not(.collapsed) {
  color: var(--primary-alt-color) !important;
  background-color: var(--primary-color) !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  border-color: var(--primary-color) !important;;
}

.dropdown-item:active {
  color: var(--primary-alt-color) !important;
  text-decoration: none !important;
  background-color: var(--primary-color) !important;
}

body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}